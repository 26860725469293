<template>
  <div>
    <el-select
      size="mini"
      :value="value"
      :disabled="isLoading"
      clearable
      @change="$emit('input', $event)"
    >
      <el-option
        v-for="(card, index) in cards"
        :key="index"
        :label="card.name"
        :value="card.id">
        <span style="float: left">{{ card.name }}<b>(id: {{ card.id }})</b></span>
        <span style="float: right;margin-left: 10px">
          <el-button size="mini" type="text"
                     @click.stop.prevent="editCard(card.id, card.name)">{{ $t('main.button.edit') }}</el-button>
        </span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  name: 'InterfaceCard',
  props: {
    objectId: {},
    value: {}
  },
  inject: ['addMainTab'],
  data () {
    return {
      cards: [],
      isLoading: true
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    objectId () {
      this.loadData()
    }
  },
  methods: {
    async loadData () {
      if (!this.objectId) {
        return
      }
      this.isLoading = true
      const payload = {
        entity_id: this.objectId,
        order: 'id',
        fields: ['id', 'name']
      }
      const { data } = await this.$http.get(`${this.$config.api}/interfaceeditor/cards?${new URLSearchParams(payload).toString()}`)
      this.isLoading = false
      this.cards = data
    },
    editCard (id, name) {
      this.addMainTab({
        name: name,
        componentType: 'CardEditor',
        payload: {
          registry_id: parseInt(this.objectId),
          card_id: parseInt(id)
        }
      })
    }
  }
}
</script>
